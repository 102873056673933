import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { getGatsbyImageData } from 'gatsby-source-sanity';

import Seo from '../SEO';
import { Footer } from '../components/footer';
import NoScript from '../components/NoScript';
import ButtonShareMobile from '../components/button/ButtonShareMobile';
import BlockText from '../components/blockText/BlockText';
import ButtonCta from '../components/button/ButtonCta';
import Navbar from '../components/navbar';
import { urlFor } from '../utils/common';

import sanityConfig from '../../config/sanity';
import CardPengaduan from '../components/cardPengaduan';
import Wrapper from '../components/Wrapper';
import GTM_TRACKERS from '../../constants/gtm-trackers';

const BlogDetail = ({ pageContext }) => {
  const [thisURL, setThisUrl] = useState('');
  useEffect(() => {
    setThisUrl(window.location.href);
  }, []);
  const cardImage = getGatsbyImageData(pageContext.mainImage, {
    placeholder: 'blurred', layout: 'fullWidth', width: 600,
  }, sanityConfig);
  let { seo } = pageContext;
  if (!seo) seo = {};

  return (
    <Wrapper>
      <Seo
        lang={pageContext.lang}
        keyword={seo.metaKeywords}
        title={seo.metaTitle || pageContext.title}
        metaDescription={seo.metaDescription}
        metaImage={urlFor(pageContext.mainImage).url()}
        type="website"
        postData={pageContext}
        cannonicalPath={`blog/${pageContext.slug.current}`}
      />
      <Navbar />
      <div className="container mx-auto relative pt-3 lg:pt-28 p-0">
        <div className="mx-4 lg:mx-0 lg:flex flex-row lg:mb-16">
          {pageContext.mainImageURL !== null
            ? (
              <div className="lg:w-1/2">
                <Link to={pageContext.mainImageURL} className="hover:no-underline cursor-pointer">
                  <GatsbyImage
                    loading="lazy"
                    image={cardImage}
                    title={pageContext.title}
                    alt={pageContext.title}
                    className="rounded-2xl"
                  />
                </Link>
              </div>
            )
            : (
              <div className="lg:w-1/2">
                <GatsbyImage
                  loading="lazy"
                  image={cardImage}
                  title={pageContext.title}
                  alt={pageContext.title}
                  className="rounded-2xl"
                />
              </div>
            )}
          <div className="lg:w-1/2 flex flex-col py-4 lg:py-0 lg:ml-8 self-center">
            <p className="text-blue-light40">{pageContext.category}</p>
            <h1 className="py-2 lg:py-5 text-xl lg:text-4xl font-bold">{pageContext.title}</h1>
            {pageContext.author !== null ? (
              <div className="mt-4 lg:mt-5">
                <p className="font-bold">{pageContext.author.name}</p>
                <p className="italic">{pageContext.publishedAt}</p>
              </div>
            )
              : (
                <div className="mt-4 lg:mt-5">
                  <p className="font-bold">DepositoBPR by Komunal</p>
                  <p className="italic">{pageContext.publishedAt}</p>
                </div>
              )}
          </div>
        </div>
        <div className="mx-auto w-11/12 md:w-8/12 lg:w-8/12">
          <BlockText body={pageContext._rawBody} title={pageContext.title} />
          <ButtonShareMobile url={thisURL} />
          <div className="flex flex-row justify-center w-full mt-10 mb-20">
            {Array.isArray(pageContext.buttonCtas) && pageContext.buttonCtas.map((btn) => (
              <ButtonCta {...btn} className={GTM_TRACKERS.BLOG.DEPOSITO_SEKARANG} />
            ))}
          </div>
        </div>
      </div>
      <CardPengaduan />
      <Footer showConnectWithUs={false} />
      <NoScript />
    </Wrapper>
  );
};

BlogDetail.propTypes = {
  pageContext: propTypes.object,
};

BlogDetail.defaultProps = {
  pageContext: {},
};

export default BlogDetail;
